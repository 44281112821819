export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_USER = "ADD_USER";
export const DELETE_CANDIDATE = "DELETE_CANDIDATE";
export const EDIT_CANDIDATE_NAME = "EDIT_CANDIDATE_NAME";
export const EDIT_DRIVE_NAME = "EDIT_DRIVE_NAME";
export const FETCH_BOTS = "FETCH_BOTS";
export const FETCH_COMPANY_BOTS = "FETCH_COMPANY_BOTS";
export const GET_CANDIDATES_BY_TYPE = "GET_CANDIDATES_BY_TYPE";
export const RESET_SUCCESS_MSG = "RESET_SUCCESS_MSG";
export const RERENDER = "RERENDER";
export const SET_CANDIDATES = "SET_CANDIDATES";
export const SET_COMPANY_BOTS = "SET_COMPANY_BOTS";
export const SET_CONVO = "SET_CONVO";
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG";
export const SET_WORKFLOW_FUNNEL_DATA = "SET_WORKFLOW_FUNNEL_DATA";
export const RESET_RERENDER = "RESET_RERENDER";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERROR_MSG = "AUTH_ERROR_MSG";
export const AUTH_SUCCESS_MSG = "AUTH_SUCCESS_MSG";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const SET_REPORT_MODEL = "SET_REPORT_MODEL";
export const USER_LOGOUT = "USER_LOGOUT";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const VERIFY_USER = "VERIFY_USER";
export const SEND_FORGOT_PASSWORD_LINK = "SEND_FORGOT_PASSWORD_LINK";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_DRIVE_DETAILS = "SET_DRIVE_DETAILS";
export const GET_DRIVE_DETAILS = "GET_DRIVE_DETAILS";
export const ADD_COMPANY = "ADD_COMPANY";
export const DEACTIVATE_COMPANY = "DEACTIVATE_COMPANY";
export const ACTIVATE_COMPANY = "ACTIVATE_COMPANY";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const SET_COMPANIES = "SET_COMPANIES";
export const ADD_BOTS = "ADD_BOTS";
export const DELETE_BOTS = "DELETE_BOTS";
export const GET_SUPERUSER = "GET_SUPERUSER";
export const SET_SUPERUSER = "SET_SUPERUSER";
export const ADD_SUPERUSER = "ADD_SUPERUSER";
export const DELETE_SUPERUSER = "DELETE_SUPERUSER";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const SET_FEEDBACK = "SET_FEEDBACK";
export const FETCH_MY_DETAILS = "FETCH_MY_DETAILS";
export const SET_MY_DETAILS = "SET_MY_DETAILS";
export const UPDATE_MY_DETAILS = "UPDATE_MY_DETAILS";
export const CHANGE_MY_PASSWORD = "CHANGE_MY_PASSWORD";
export const ADD_PRE_SCREENING_BOT = "ADD_PRE_SCREENING_BOT";
export const EDIT_PRE_SCREENING_BOT = "EDIT_PRE_SCREENING_BOT";
export const FETCH_PRE_SCREENING_BOTS = "FETCH_PRE_SCREENING_BOTS";
export const DELETE_PRE_SCREENING_BOT = "DELETE_PRE_SCREENING_BOT";
export const SET_PRE_SCREENING_BOTS = "SET_PRE_SCREENING_BOTS";
export const VERIFY_LINK_TOKEN = "VERIFY_LINK_TOKEN";
export const FETCH_SAAS_PLAN_LIST = "FETCH_SAAS_PLAN_LIST";
export const SET_SAAS_PLAN_LIST = "SET_SAAS_PLAN_LIST";
export const FETCH_SAAS_PLAN_DETAILS = "FETCH_SAAS_PLAN_DETAILS";
export const SET_SAAS_PLAN_DETAILS = "SET_SAAS_PLAN_DETAILS";
export const UPDATE_SAAS_PLAN_DETAILS = "UPDATE_SAAS_PLAN_DETAILS";
export const UPDATE_ASSESSMENT_BOT_LOGO = "UPDATE_ASSESSMENT_BOT_LOGO";
export const UPDATE_DASHBOARD_LOGO = "UPDATE_DASHBOARD_LOGO";
export const SET_DASHBOARD_LOGO = "SET_DASHBOARD_LOGO";
export const CREATE_WORKFLOW_JOB_ROLE = "CREATE_WORKFLOW_JOB_ROLE";
export const FETCH_WORKFLOW_DETAILS = "FETCH_WORKFLOW_DETAILS";
export const SET_JOB_ROLE_DETAILS = "SET_JOB_ROLE_DETAILS";
export const SET_CREATE_JOB_LOADING = "SET_CREATE_JOB_LOADING";
export const SEND_PRE_SCREENING_TEST = "SEND_PRE_SCREENING_TEST";
export const SET_TRIAL_USERS_LIST = "SET_TRIAL_USERS_LIST";
export const FETCH_TRIAL_USERS_LIST = "FETCH_TRIAL_USERS_LIST";
export const SEND_UPDATE_ALL = "SEND_UPDATE_ALL";
export const UPDATE_COMPANY_FEATURES = "UPDATE_COMPANY_FEATURES";
export const FETCH_COMPANY_DETAILS = "FETCH_COMPANY_DETAILS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const UPDATE_BOT_DETAILS = "UPDATE_BOT_DETAILS";
export const UPDATE_COMPANY_GUIDELINES = "UPDATE_COMPANY_GUIDELINES";
export const SEND_SUPPORT_MSG = "SEND_SUPPORT_MSG";
export const UPDATE_PRESCREENING_DETAILS = "UPDATE_PRESCREENING_DETAILS";
export const SET_APPLICANTS_LOADING = "SET_APPLICANTS_LOADING";
export const SET_CURRENT_WALKTHROUGH_STEP = "SET_CURRENT_WALKTHROUGH_STEP";
export const SET_CURRENT_WALKTHROUGH_TYPE = "SET_CURRENT_WALKTHROUGH_TYPE";
export const SET_SUPPORT_MSGS = "SET_SUPPORT_MSGS";
export const FETCH_SUPPORT_MSGS = "FETCH_SUPPORT_MSGS";
export const ADD_CHATBOX_EVENTS = "ADD_CHATBOX_EVENTS";
export const SET_WALKTHROUGH_FLOWS = "SET_WALKTHROUGH_FLOWS";
export const FETCH_WORKFLOWS = "FETCH_WORKFLOWS";
export const SET_WORKFLOWS = "SET_WORKFLOWS";
export const FETCH_CHAT_EVENTS = "FETCH_CHAT_EVENTS";
export const SET_CHAT_EVENTS = "SET_CHAT_EVENTS";
export const SET_WALKTHROUGH_WELCOME = "SET_WALKTHROUGH_WELCOME";
export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const SET_OTP_VERIFICATION_STATUS = "SET_OTP_VERIFICATION_STATUS";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const EDIT_DRIVE_NAME_LOADING = "EDIT_DRIVE_NAME_LOADING";
export const RESTART_CANDIDATE_TEST = "RESTART_CANDIDATE_TEST";
export const FETCH_SKILL_CODES = "FETCH_SKILL_CODES";
export const SET_SKILL_CODES = "SET_SKILL_CODES";
export const UPDATE_SKILL_CODE = "UPDATE_SKILL_CODE";
export const ADD_SKILL_CODE = "ADD_SKILL_CODE";
export const DELETE_SKILL_CODE = "DELETE_SKILL_CODE";
export const FETCH_COMPANY_USERS = "FETCH_COMPANY_USERS";
export const SET_COMPANY_USERS = "SET_COMPANY_USERS";
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const UPDATE_WORKFLOW_ADMIN = "UPDATE_WORKFLOW_ADMIN";
export const UPDATE_CANDIDATE_RESUME = "UPDATE_CANDIDATE_RESUME";
export const UPDATE_VIDEO_ANSWER = "UPDATE_VIDEO_ANSWER";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const POST_PROGRESS_TO_REVIEW_CANDIDATES =
    "POST_PROGRESS_TO_REVIEW_CANDIDATES";
export const SET_PROGRESS_TO_REVIEW_CANDIDATES_LOADING =
    "SET_PROGRESS_TO_REVIEW_CANDIDATES_LOADING";
export const SET_CANDIDATE_TEST_REPORT = "SET_CANDIDATE_TEST_REPORT";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const RESET_ERROR_MSG = "RESET_ERROR_MSG";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const SET_COMPANY = "SET_COMPANY";
export const SET_BACKUP_WORKFLOWS = "SET_BACKUP_WORKFLOWS";
