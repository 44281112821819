import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

import Modal from "../../components/common/CustomModal";
import IconButton from "../../components/common/IconButton";
import Button from "../../components/common/Button";
import axios from "../../utils/axios";
import useMenu from "../../hooks/useMenu";
import BCGReport from "../BCGReport";
import EmptyTab from "../reports/EmptyTab";
import InterviewerGuide from "./InterviewerGuide";
import PdfReport from "../PdfReport";
import PDFTranscript from "../PdfTranscript";
import ReportTab from "../reports/ReportTab";
import WalkthroughOverlay from "./WalkthroughOverlay";
import VideoCard from "../videoSubmission/VideoCard";
import Accordion from "./Accordion";
import VideoAnswer from "../../components/applicant/VideoAnswer";

import {
    getCandidateTestReport,
    getCompanyFeatures,
    getCurrentWalkthroughStep,
    getCurrentWalkthroughType,
} from "../../selectors/app";
import {
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    getCandidatesByType as getCandidatesByTypeRequest,
} from "../../actions/app";
import { redirectUnauthorisedUser } from "../../helper/helperFunction";
import { checkCompanyFeatureAccess } from "../../utils/featureAccess";
import { getQuestionText, getTimeTaken } from "../../utils/questions";

import downloadactiveicon from "../../assets/downloadactive.png";

// Helper function to generate the PDF document based on the chat and product data
function getPDFDocument(
    chat,
    videoCardData,
    doughnutRef,
    barChartRef,
    workFunctions,
    companyFeatures
) {
    if (
        chat?.productId &&
        chat.productId.customId &&
        chat.productId.customId.toLowerCase().includes("chatbotbcgriseai")
    ) {
        return (
            <BCGReport
                videoCardData={videoCardData}
                doughnutRef={doughnutRef}
                candidateData={chat}
            />
        );
    } else if (
        chat?.productId &&
        chat.productId.customId &&
        (chat.productId.customId.toLowerCase().includes("sfs") ||
            chat.productId.customId.toLowerCase().includes("smm"))
    ) {
        return <PDFTranscript candidateData={chat} />;
    } else {
        return (
            <PdfReport
                doughnutRef={doughnutRef}
                barChartRef={barChartRef}
                candidateData={chat}
                reportWorkFunctions={workFunctions}
                detailedReport={checkCompanyFeatureAccess(
                    companyFeatures,
                    "detailedReport"
                )}
            />
        );
    }
}

// Helper function to generate the PDF file name
function getFileName(chat, location, driveDetails, type) {
    const name = chat?.name ? chat.name.split(" ").join("_") : "report";
    const workflowName = location.state?.workflowName
        ? location.state.workflowName.split(" ").join("_")
        : driveDetails.name.split(" ").join("_") ?? type;
    const fileName = `${name}_${workflowName}.pdf`;
    return fileName;
}

function PDFDownload({ label }) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            }}
            id="reviewedFlow-selector-16"
        >
            <img
                src={downloadactiveicon}
                alt="download-active-icon"
                width={24}
                height={24}
            />
            <p>{label}</p>
        </div>
    );
}

PDFDownload.propTypes = {
    label: PropTypes.string,
};

const generatePdfDocument = async (document, fileName) => {
    const blob = await pdf(document).toBlob();
    saveAs(blob, fileName);
};

const AnalyseModal = (props) => {
    const {
        open,
        onClose,
        chat,
        changeStatus,
        doughnutRef,
        barChartRef,
        isOpen,
        companyFeatures,
        driveDetails,
        workFunctions,
        currentWalkthroughStep,
        currentWalkthroughType,
        nextWalkthroughStep,
        setNextWalkthroughStep,
        updateCurrentWalkthroughStep,
        updateVideoSubmissionScore,
        getCandidatesByType,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const gotVidResult = chat?.test ? true : false;
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");
    const {
        handleClose,
        anchorEl,
        open: openMenu,
        handleClick,
        menuSX,
    } = useMenu();

    const [currentReportTab, setCurrentReportTab] = useState(0);
    const [videoCardData, setVideoCardData] = useState([]);
    const [freeTextData, setFreeTextData] = useState([]);
    const [candidateChatArr, setCandidateChatArr] = useState([]);
    const [videoCardLoading, setVideoCardLoading] = useState(false);
    const [isFreeTextLoading, setFreeTextLoading] = useState(false);

    const shouldShowReport = !isOpen && companyFeatures.reports && gotVidResult;

    const productId = chat?.productId;
    const customId = chat?.productId?.customId;

    const isVideoProduct = customId === "Video";

    const isBCGProduct =
        customId?.toLowerCase()?.includes("chatbotbcgriseai") ||
        customId?.toLowerCase()?.includes("sfs") ||
        customId?.toLowerCase()?.includes("smm");

    const handleReportScroll = () => {
        if (currentReportTab != 0) return;

        const scrollDown = document.getElementById("scroll-down-animation");
        if (scrollDown) {
            scrollDown.style.display = "none";
        }
    };

    useEffect(() => {
        const candidateTestChat = chat?.test?.chat;

        (async () => {
            setVideoCardLoading(true);
            let tempArr = [];
            const cids = [];
            for (let i = 0; i < candidateTestChat?.length; i++) {
                let val = candidateTestChat[i];
                if (
                    val.ans &&
                    !val.ans.Workbook &&
                    !val.ans.sheetid &&
                    (val.ans.includes(".mp4") ||
                        val.ans.includes(".webm") ||
                        val.ans.includes(".mp3"))
                ) {
                    let viddata = { case: val.ans };
                    if (
                        productId &&
                        customId &&
                        customId.toLowerCase().includes("chatbotbcgriseai")
                    ) {
                        viddata["case"] = customId;
                    }
                    let res = await axios.post("/cand-video", viddata);
                    let tempData = {
                        ques: val.ques,
                        score: val.score,
                        src: res.data.signedUrl,
                        cid: val.cid,
                        notes: val.notes,
                    };
                    if (!cids.includes(val.cid)) {
                        cids.push(val.cid);
                        tempArr.push(tempData);
                    }
                }
            }

            setVideoCardData(tempArr);
            setVideoCardLoading(false);
        })();

        if (candidateTestChat?.length) {
            const newChatArr = [];
            const newFreeTextChatArr = [];
            const cids = [];
            candidateTestChat.forEach((item) => {
                if (!cids.includes(item.cid)) {
                    cids.push(item.cid);
                    newChatArr.push(item);
                    if (item.qtype === "txt0") {
                        newFreeTextChatArr.push(item);
                    }
                }
            });

            setCandidateChatArr([...newChatArr]);
            setFreeTextData([...newFreeTextChatArr]);
        }
    }, [chat]);

    useEffect(() => {
        if (companyFeatures["reports"]) {
            setCurrentReportTab(0);
        } else if (companyFeatures["videoSubmissions"]) {
            setCurrentReportTab(1);
        } else if (companyFeatures["transcripts"]) {
            setCurrentReportTab(2);
        } else if (companyFeatures["interviewersGuide"]) {
            setCurrentReportTab(3);
        } else if (companyFeatures["freeTextSubmissions"]) {
            setCurrentReportTab(4);
        }
        if (productId && customId === "Video") {
            setCurrentReportTab(1);
        }
    }, []);

    const onClickApprove = (cid, chatIndex) => {
        setFreeTextLoading(true);
        axios
            .post("/save-free-text-score", {
                cid,
                testid: chat.test._id,
                score: freeTextData[chatIndex].score,
            })
            .then((res) => {
                console.log("freeTextScoreSubmitResponse::", res);
                setFreeTextLoading(false);
            })
            .catch((err) => {
                redirectUnauthorisedUser(err, navigate);
            });
    };

    const onChangeFreeTextScore = (e, chatIndex) => {
        const newFreeTextChatArr = [...freeTextData];
        newFreeTextChatArr[chatIndex].score = e.target.value;
        setFreeTextData([...newFreeTextChatArr]);
    };

    useEffect(() => {
        if (currentWalkthroughStep === 6) setCurrentReportTab(1);
        if (currentWalkthroughStep === 7) setCurrentReportTab(2);
        if (currentWalkthroughStep === 8)
            setCurrentReportTab(companyFeatures["interviewersGuide"] ? 3 : 4);
    }, [currentWalkthroughStep]);

    const onCloseModal = () => onClose();

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{ modal: "customModalChats" }}
        >
            <div className="container-team">
                <div className="heading-team">
                    <div>
                        <span
                            style={{
                                fontSize: mediaQueryMobile.matches ? 20 : 24,
                                fontWeight: "600",
                                color: "#479BD2",
                            }}
                        >
                            {chat?.name}
                        </span>
                        {chat?.test ? (
                            <span
                                style={{
                                    borderLeft: "1px solid rgb(150, 150, 150)",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                }}
                            >
                                Time taken:{" "}
                                {getTimeTaken(
                                    chat?.test.endDate,
                                    chat?.test.createdDate
                                )}{" "}
                                mins
                            </span>
                        ) : null}
                    </div>
                    <div className="action-btns-box">
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeStatus("Shortlisted", [chat]);
                                    }}
                                    className="check-btn"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Shortlist</span>
                        </div>
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeStatus("Rejected", [chat]);
                                    }}
                                    className="cancel-btn"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Reject</span>
                        </div>
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeStatus("Kept In View", [chat]);
                                    }}
                                    className="visibility-btn"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Keep in view</span>
                        </div>
                    </div>
                </div>
                <div className="report-tabs-container">
                    <div className="report-tabs dashboard-forth-step">
                        {companyFeatures["reports"] ? (
                            productId && customId === "Video" ? null : (
                                <div
                                    id="evaluateStep5"
                                    className="tooltip tabContainer"
                                    style={{
                                        zIndex:
                                            currentWalkthroughStep === 5 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? 1000
                                                : "auto",
                                        backgroundColor:
                                            currentWalkthroughStep === 5 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? "#fff"
                                                : "transparent",
                                    }}
                                >
                                    <div
                                        className={`singleTab
                                            ${
                                                currentReportTab == 0
                                                    ? "active-report-tab"
                                                    : null
                                            }`}
                                        onClick={() => setCurrentReportTab(0)}
                                    >
                                        Report
                                    </div>
                                    <span className="tooltiptext">
                                        View report
                                    </span>
                                </div>
                            )
                        ) : null}
                        {companyFeatures["videoSubmissions"] ? (
                            <div
                                id="evaluateStep6"
                                className="tooltip tabContainer"
                                style={{
                                    zIndex:
                                        currentWalkthroughStep === 6 &&
                                        currentWalkthroughType === "evaluate"
                                            ? 1000
                                            : "auto",
                                    backgroundColor:
                                        currentWalkthroughStep === 6 &&
                                        currentWalkthroughType === "evaluate"
                                            ? "#fff"
                                            : "transparent",
                                }}
                            >
                                <div
                                    className={`singleTab
                                            ${
                                                currentReportTab == 1
                                                    ? "active-report-tab"
                                                    : null
                                            }`}
                                    onClick={() => setCurrentReportTab(1)}
                                >
                                    Video submissions
                                </div>
                                <span className="tooltiptext">
                                    View and score video submissions
                                </span>
                            </div>
                        ) : null}
                        {companyFeatures["transcripts"] ? (
                            productId && customId === "Video" ? null : (
                                <div
                                    id="evaluateStep7"
                                    className="tooltip tabContainer"
                                    style={{
                                        zIndex:
                                            currentWalkthroughStep === 7 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? 1000
                                                : "auto",
                                        backgroundColor:
                                            currentWalkthroughStep === 7 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? "#fff"
                                                : "transparent",
                                    }}
                                >
                                    <div
                                        className={`singleTab
                                            ${
                                                currentReportTab == 2
                                                    ? "active-report-tab"
                                                    : null
                                            }`}
                                        onClick={() => setCurrentReportTab(2)}
                                    >
                                        Chats
                                    </div>
                                    <span className="tooltiptext">
                                        View transcript
                                    </span>
                                </div>
                            )
                        ) : null}
                        {companyFeatures["interviewersGuide"] ? (
                            productId && customId === "Video" ? null : (
                                <div
                                    id="evaluateStep8"
                                    className="tooltip tabContainer"
                                    style={{
                                        zIndex:
                                            currentWalkthroughStep === 8 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? 1000
                                                : "auto",
                                        backgroundColor:
                                            currentWalkthroughStep === 8 &&
                                            currentWalkthroughType ===
                                                "evaluate"
                                                ? "#fff"
                                                : "transparent",
                                    }}
                                >
                                    <div
                                        className={`singleTab
                                            ${
                                                currentReportTab == 3
                                                    ? "active-report-tab"
                                                    : null
                                            }`}
                                        onClick={() => setCurrentReportTab(3)}
                                    >
                                        Interviewer&apos;s guide
                                    </div>
                                    <span className="tooltiptext">
                                        Literature review on assessment basics
                                    </span>
                                </div>
                            )
                        ) : null}
                        {companyFeatures["freeTextSubmissions"] ? (
                            <div
                                id="evaluateStep8"
                                className="tooltip tabContainer"
                                style={{
                                    zIndex:
                                        currentWalkthroughStep === 8 &&
                                        currentWalkthroughType === "evaluate"
                                            ? 1000
                                            : "auto",
                                    backgroundColor:
                                        currentWalkthroughStep === 8 &&
                                        currentWalkthroughType === "evaluate"
                                            ? "#fff"
                                            : "transparent",
                                }}
                            >
                                <div
                                    className={`singleTab
                                        ${
                                            currentReportTab == 4
                                                ? "active-report-tab"
                                                : null
                                        }`}
                                    onClick={() => setCurrentReportTab(4)}
                                >
                                    Free Text Submissions
                                </div>
                                <span className="tooltiptext">
                                    View and score free text submissions
                                </span>
                            </div>
                        ) : null}
                    </div>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            borderRadius: 25,
                        }}
                        onClick={handleClick}
                    >
                        Download
                    </Button>
                    <Menu
                        sx={menuSX}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {shouldShowReport &&
                            (productId &&
                            (isVideoProduct || isBCGProduct) ? null : (
                                <MenuItem
                                    onClick={() =>
                                        generatePdfDocument(
                                            <PDFTranscript
                                                candidateData={chat}
                                            />,
                                            getFileName(
                                                chat,
                                                location,
                                                driveDetails,
                                                "transcript"
                                            )
                                        )
                                    }
                                >
                                    <PDFDownload label="Transcript" />
                                </MenuItem>
                            ))}
                        {shouldShowReport &&
                            (productId && isVideoProduct ? null : (
                                <MenuItem
                                    onClick={() =>
                                        generatePdfDocument(
                                            getPDFDocument(
                                                chat,
                                                videoCardData,
                                                doughnutRef,
                                                barChartRef,
                                                workFunctions,
                                                companyFeatures
                                            ),
                                            getFileName(
                                                chat,
                                                location,
                                                driveDetails,
                                                "report"
                                            )
                                        )
                                    }
                                >
                                    <PDFDownload label="Report" />
                                </MenuItem>
                            ))}
                    </Menu>
                </div>
                <div className="scrollblue" onScroll={handleReportScroll}>
                    {currentReportTab == 2 ? (
                        candidateChatArr.length ? (
                            candidateChatArr.map((row, index) => (
                                <Accordion
                                    key={index}
                                    variant={"chats"}
                                    title={`Q.${index + 1} ${getQuestionText(
                                        row
                                    )}`}
                                >
                                    {typeof row.ans === "string" ||
                                    row.ans instanceof String ? (
                                        <div
                                            style={{
                                                color: "rgb(110,110,110)",
                                            }}
                                        >
                                            <b>Answer- </b>{" "}
                                            {row.ans.includes(".mp4") ||
                                            row.ans.includes(".webm") ||
                                            row.ans.includes(".mp3") ? (
                                                <VideoAnswer
                                                    code={chat?.code}
                                                />
                                            ) : row.ans
                                                  .toLowerCase()
                                                  .includes(
                                                      "excel submitted"
                                                  ) &&
                                              row.detailed_score?.length &&
                                              row.detailed_score[0]?.sheetid ? (
                                                <a
                                                    rel="noreferrer"
                                                    style={{
                                                        color: "inherit",
                                                    }}
                                                    href={`https://docs.google.com/spreadsheets/d/${row.detailed_score[0].sheetid}/edit`}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            ) : row?.qtype?.includes("seq") ? (
                                                <ol>
                                                    {row.ans
                                                        .split("::")
                                                        .map((answer) => (
                                                            <li key={answer}>
                                                                {answer}
                                                            </li>
                                                        ))}
                                                </ol>
                                            ) : (
                                                row.ans.replaceAll("::", ". ")
                                            )}
                                        </div>
                                    ) : null}
                                </Accordion>
                            ))
                        ) : (
                            <EmptyTab
                                text={"Couldn't find chats for this candidate"}
                            />
                        )
                    ) : null}
                    {currentReportTab == 1 ? (
                        chat?.test?.chat?.length ? (
                            <>
                                {videoCardLoading ? (
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="videobox-loader" />
                                    </div>
                                ) : (
                                    <div
                                        className="videobox-wrapper"
                                        style={{
                                            justifyContent: videoCardData.length
                                                ? "normal"
                                                : "center",
                                        }}
                                    >
                                        {videoCardData.length == 0
                                            ? "No Video"
                                            : videoCardData.map((val) => (
                                                  <VideoCard
                                                      key={val.cid}
                                                      ques={val.ques}
                                                      src={val.src}
                                                      score={val.score}
                                                      cid={val.cid}
                                                      notes={val.notes}
                                                      candidateData={chat}
                                                      updateVideoSubmissionScore={
                                                          updateVideoSubmissionScore
                                                      }
                                                  />
                                              ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <EmptyTab
                                text={
                                    "Couldn't find video submission for this candidate"
                                }
                            />
                        )
                    ) : null}
                    {currentReportTab == 0 ? (
                        chat?.test?.chat?.length ? (
                            productId && customId === "Video" ? null : (
                                <ReportTab
                                    candidateData={chat}
                                    setCurrentReportTab={setCurrentReportTab}
                                    workFunctions={workFunctions}
                                />
                            )
                        ) : (
                            <EmptyTab
                                text={"Couldn't find report for this candidate"}
                            />
                        )
                    ) : null}
                    {currentReportTab == 3 ? (
                        <InterviewerGuide
                            productId={productId}
                            reportWorkFunctions={workFunctions}
                        />
                    ) : null}
                    {currentReportTab == 4 ? (
                        freeTextData.length ? (
                            freeTextData.map((row, index) => (
                                <Accordion
                                    key={index}
                                    variant={"chats"}
                                    title={`Q.${index + 1} ${getQuestionText(
                                        row
                                    )}`}
                                    canOpen={true}
                                >
                                    {typeof row.ans === "string" ||
                                    row.ans instanceof String ? (
                                        <div
                                            style={{
                                                color: "rgb(110,110,110)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ width: "80%" }}>
                                                    <b>Answer- </b>{" "}
                                                    {row.ans.includes(".mp4") ||
                                                    row.ans.includes(
                                                        ".webm"
                                                    ) ? (
                                                        <VideoAnswer
                                                            code={chat?.code}
                                                        />
                                                    ) : (
                                                        row.ans.replaceAll(
                                                            "::",
                                                            ". "
                                                        )
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        width: "20%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-end",
                                                        paddingRight: 20,
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        style={{
                                                            height: 40,
                                                            backgroundColor:
                                                                "#e6e6e6",
                                                            width: 80,
                                                            borderRadius: 80,
                                                            fontWeight: "700",
                                                            paddingVertical: 5,
                                                            paddingHorizontal: 10,
                                                            marginBottom: 17,
                                                            color: "#000",
                                                            textAlign: "center",
                                                            fontSize: 14,
                                                            outline: "none",
                                                            border: "none",
                                                        }}
                                                        value={
                                                            row.score
                                                                ? row.score
                                                                : 0
                                                        }
                                                        onChange={(e) =>
                                                            onChangeFreeTextScore(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <Button
                                                        style={{
                                                            textAlign: "center",
                                                            padding: 10,
                                                            backgroundColor:
                                                                "#03C04A",
                                                            color: "#fff",
                                                            borderRadius: 80,
                                                            overflow: "hidden",
                                                            width: 80,
                                                            outline: "none",
                                                            border: "none",
                                                        }}
                                                        disabled={
                                                            isFreeTextLoading
                                                        }
                                                        onClick={() =>
                                                            onClickApprove(
                                                                row.cid,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        Approve
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </Accordion>
                            ))
                        ) : (
                            <EmptyTab
                                text={
                                    "Couldn't find free text submissions for this candidate"
                                }
                            />
                        )
                    ) : null}
                </div>
                {currentWalkthroughStep >= 5 && currentWalkthroughStep <= 8 ? (
                    <WalkthroughOverlay
                        showWalkthroughNextBtn={true}
                        onClickDone={() => {
                            updateCurrentWalkthroughStep(nextWalkthroughStep);
                            setNextWalkthroughStep(nextWalkthroughStep + 1);
                        }}
                        onClickBack={() => {
                            if (nextWalkthroughStep === 6) onClose();
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep - 1
                            );
                            setNextWalkthroughStep(nextWalkthroughStep - 1);
                        }}
                    />
                ) : null}
            </div>
        </Modal>
    );
};

AnalyseModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    chat: PropTypes.object,
    changeStatus: PropTypes.func,
    doughnutRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    barChartRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    isOpen: PropTypes.bool,
    companyFeatures: PropTypes.object,
    driveDetails: PropTypes.object,
    workFunctions: PropTypes.object,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    nextWalkthroughStep: PropTypes.number,
    setNextWalkthroughStep: PropTypes.func,
    updateCurrentWalkthroughStep: PropTypes.func,
    updateVideoSubmissionScore: PropTypes.func,
    getCandidatesByType: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    companyFeatures: getCompanyFeatures(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
    chat: getCandidateTestReport(),
});

const mapDispatchToProps = (dispatch) => ({
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseModal);
