import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Bar, Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CSVLink } from "react-csv";
import { Drawer } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useTour } from "@reactour/tour";

import AccountMenu from "../components/AccountMenu";
import AnalyseModal from "../components/common/AnalyseModal";
import Avatar from "../components/common/Avatar";
import axios from "../utils/axios";
import BottomBar from "../components/common/BottomBar";
import Button from "../components/common/Button";
import DataTable from "../components/common/DataTable";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Modal from "../components/common/CustomModal";
import NotificationBell from "../components/notifications/NotificationBell";
import PdfTranscript from "../components/PdfTranscript";
import ProctoringModal from "../components/common/ProctoringModal";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import {
    barChartData,
    doughnutOptions,
    reportWorkFunctions,
} from "../constants/reports";
import { doughnutData, getCandidateStatus } from "../utils/reports";
import {
    getCandidatesList,
    getRerender,
    getIsReportModelOpen,
    getUserRole,
    getApplicantsLoading,
    getCurrentWalkthroughStep,
    getCurrentWalkthroughType,
    getDriveDetails,
    getSkillCodeList,
    checkIfLoading,
} from "../selectors/app";
import {
    getCandidatesByType as getCandidatesByTypeRequest,
    editCandidateName as editCandidateNameRequest,
    resetSuccessMsg as resetSuccessMsgRequest,
    resetRerender as resetRerenderRequest,
    deleteCandidate as deleteCandidateRequest,
    setReportModel as setReportModelRequest,
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
    updateWalkthroughFlows as updateWalkthroughFlowsRequest,
    fetchDriveDetails as fetchDriveDetailsRequest,
    fetchSkillCodes as fetchSkillCodesRequest,
    setCandidates as setCandidatesRequest,
    setCandidateTestReport as setCandidateTestReportRequest,
    setSuccessMsg as setSuccessMsgRequest,
    setErrorMsg as setErrorMsgRequest,
} from "../actions/app";
import { redirectUnauthorisedUser } from "../helper/helperFunction";
import { useWindowDimensions } from "../utils/windowUtils";

import Cancel from "../assets/verification-error.png";
import CandidateCVIcon from "../assets/candidate-cv.png";
import Edit from "../assets/Icons/Edit.png";
import emptyRowImg from "../assets/emptyrow2.png";
import Subtract from "../assets/Icons/Subtract.png";
import TickBlueIcon from "../assets/right-blue-icon.svg";
import Trash from "../assets/Icons/Trash.png";

import "../styles/reviewApplicants.scss";
import NoRowsOverlay from "../components/common/NoRowsOverlay";
import ConcludeAssessment from "../components/reviewApplicants/ConcludeAssessment";
import { DELETE_CANDIDATE, EDIT_CANDIDATE_NAME } from "../constants/app";
import Loader from "../components/common/Loader";

const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

function getToBeReviewedCandidates(candidates) {
    return candidates.filter(
        (candidate) => candidate.status == "Complete"
    );
}

const pdfUrl = process.env.REACT_APP_BASE_SERVER_URL_FULL + "/pdfreport";

const ReviewApplicants = (props) => {
    const {
        candidates,
        setCandidates,
        getCandidatesByType,
        rerender,
        editCandidateName,
        resetRerender,
        deleteCandidate,
        isReportModelOpen,
        setReportModel,
        notification,
        userRole,
        applicantsLoading,
        currentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughStep,
        updateCurrentWalkthroughType,
        updateWalkthroughFlows,
        fetchDriveDetails,
        driveDetails,
        fetchSkillCodes,
        skillCodeList,
        setCandidateTestReport,
        setErrorMsg,
        setSuccessMsg,
        isEditingName,
        isDeletingCand,
    } = props;

    const settings = JSON.parse(localStorage.getItem("settings"));
    const companyId = localStorage.getItem("company");

    const editNameRef = useRef(null);
    const doughnutRef = useRef();
    const barChartRef = useRef();
    const transcriptDownloadRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const { isOpen } = useTour();
    const windowDimensions = useWindowDimensions();

    const { sidebar, workflowId, workflowName } = location.state || {};
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

    const [deleteModal, setDeleteModal] = useState(false);
    const [chat, setChat] = useState({});
    const [change, setChange] = useState(0);
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [barData, setBarData] = useState({ ...barChartData });
    const [transcriptPdfLoaded, setTranscriptPdfLoaded] = useState(true);
    const [bottomDrawer, setBottomDrawer] = useState(false);
    const [proctoringModal, setProctoringModal] = useState(false);
    const [proctoringData, setProctoringData] = useState({});
    const [checkboxOptions, setCheckboxOptions] = useState({
        shortlist: false,
        reject: false,
        keptInView: false,
    });
    const [nextWalkthroughStep, setNextWalkthroughStep] = useState(4);
    const [filterScoreKeysArr, setFilterScoreKeysArr] = useState([]);
    const [filterScoreKey, setFilterScoreKey] = useState([]);
    const [workFunctions, setWorkFunctions] = useState({});
    const [checkButtonDisabled, setCheckButtonDisabled] = useState({});

    const onOpenDeleteModal = () => {
        if (mediaQueryMobile.matches) {
            if (selectedRow.length > 0) setDeleteModal(true);
            else {
                setErrorMsg("Please select atleast one candidate");
            }
        } else {
            if (selectionModel.length == 0) {
                setErrorMsg("Please select atleast one candidate");
            } else setDeleteModal(true);
        }
    };

    const onCloseDeleteModal = () => setDeleteModal(false);

    const onOpenModal = (e, params) => {
        if (params.row?.productId?.customId === "Video") {
            navigate(`/video-applicant/${params.row._id}`, {
                state: {
                    sidebar,
                    workflowId,
                    workflowName,
                },
            });
        } else {
            if (e) e.stopPropagation();
            if (mediaQueryMobile.matches) {
                if (selectedRow.length === 0) {
                    setErrorMsg("Please select a candidate");
                } else if (selectedRow.length > 1) {
                    setErrorMsg("Please select only one candidate");
                } else {
                    filterBarData(params);
                }
            } else {
                if (
                    currentWalkthroughStep === 4 &&
                    currentWalkthroughType === "evaluate"
                ) {
                    updateCurrentWalkthroughStep(nextWalkthroughStep);
                    setNextWalkthroughStep(nextWalkthroughStep + 1);
                }
                filterBarData(params);
            }
        }
    };

    const filterBarData = (params) => {
        setChat(params.row);
        if (
            params.row?.productId &&
            params.row.productId?.customId === "Video"
        ) {
            setTotalScore(0);
        } else {
            setTotalScore(parseInt((params.row.test.result.total || 0) * 100));
        }

        const newBarData = { ...barData };

        newBarData.labels = [];
        newBarData.datasets[0].data = [];
        newBarData.datasets[1].data = [];

        if (
            params.row.test.chat &&
            params.row.test.chat.length > 0 &&
            Object.prototype.hasOwnProperty.call(
                params.row.test.chat[0],
                "idealqtime"
            )
        ) {
            const newChatArr = [];
            const cids = [];
            params.row.test.chat.forEach((item) => {
                if (!cids.includes(item.cid)) {
                    cids.push(item.cid);
                    newChatArr.push(item);
                }
            });

            newChatArr.forEach((item, index) => {
                newBarData.labels.push("Q" + (1 + index));
                newBarData.datasets[0].data.push(item.idealqtime);
                newBarData.datasets[1].data.push(item.userqtime);
            });
        }

        setBarData({ ...newBarData });
        setCandidateTestReport(params.row);
        setReportModel(true);
    };

    const onCloseModal = () => setReportModel(false);

    useEffect(() => {
        if (rows.length == 0) return;

        let temp = [];
        rows.map((val) => {
            let score = 0.0;
            let index = 0;

            try {
                if (
                    Object.prototype.hasOwnProperty.call(
                        val.test.result || {},
                        "total"
                    )
                )
                    score = val.test.result["total"];
                else {
                    for (let keys in val.test.result) {
                        score += val.test.result[keys];
                        index += 1;
                    }
                    if (index > 0) score = score / index;
                }
                if (score > 0) score *= 100;
            } catch (err) {
                console.log(err);
            }

            let x = {
                name: val.name,
                email: val.email,
                id: val.id,
                status: getCandidateStatus(val.status),
                score: score,
            };
            temp.push(x);
        });

        setCSVData(temp);
    }, [rows]);

    useEffect(() => {
        const reqData = {
            status: "candidates",
            id: workflowId,
        };

        getCandidatesByType({ reqData, navigate });
    }, [change]);

    function nameEdit(row) {
        editCandidateName({
            row,
            newName: editNameRef.current.value,
            navigate,
            onSuccess: () => setSelectionModel([]),
        });
    }

    const updateVideoSubmissionScore = (
        candidateId,
        vidNotes,
        inputScore,
        cid,
        testid,
        handleSuccess,
        handleError
    ) => {
        axios
            .post("/savenotes", {
                notes: vidNotes,
                score: inputScore,
                cid,
                testid,
            })
            .then(() => {
                handleSuccess();
                let tempRows = [...candidates];
                const rowToUpdate = tempRows.reduce((acc, curr) => {
                    if (curr.id === candidateId) {
                        const tempCurr = curr.test.chat.map((item) => {
                            if (item.cid === cid) {
                                return {
                                    ...item,
                                    score: inputScore,
                                    notes: vidNotes,
                                };
                            }
                            return item;
                        });
                        acc.push({
                            ...curr,
                            test: {
                                ...curr.test,
                                chat: [...tempCurr],
                            },
                        });
                    }

                    return acc;
                }, []);
                tempRows = tempRows.filter((item) => item.id !== candidateId);
                tempRows = [...tempRows, ...rowToUpdate];
                setCandidates([...tempRows]);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const handleDelete = (rowSelected) => {
        const IDs = [];
        rowSelected.map((val) => {
            IDs.push(val._id);
        });
        deleteCandidate({ id: IDs, navigate, onSuccess: onCloseDeleteModal });
    };

    useEffect(() => {
        if (!rerender) return;

        setChange(change + 1);
        resetRerender();

        if (editModal) onCloseEditModal();
        if (deleteModal) onCloseDeleteModal();
    }, [rerender]);

    useEffect(() => {
        if (!workflowName) {
            fetchDriveDetails({
                workflowId,
                navigate,
            });
        }
        fetchSkillCodes();
    }, []);

    useEffect(() => {
        const wfKeyArr = [];
        const newRwf = {};
        Object.keys(reportWorkFunctions).forEach((rwf) => {
            wfKeyArr.push(rwf);
            newRwf[rwf] = { ...reportWorkFunctions[rwf] };
        });
        Object.keys(skillCodeList).forEach((scl) => {
            if (!wfKeyArr.includes(scl)) {
                newRwf[scl] = { ...skillCodeList[scl] };
            }
        });
        setWorkFunctions({ ...newRwf });
    }, [skillCodeList]);

    useEffect(() => {
        if (!candidates.length) {
            setData([]);
            setRows([]);
            return;
        }

        const filteredCandidates = getToBeReviewedCandidates(candidates)

        filteredCandidates.forEach((candidate) => {
            if (
                candidate?.test &&
                candidate.test?.result &&
                Object.prototype.hasOwnProperty.call(
                    candidate.test.result,
                    "total"
                )
            ) {
                setFilterScoreKeysArr([...Object.keys(candidate.test.result)]);
                candidate.score = candidate.test.result.total;
            } else {
                candidate.score = 0;
                candidate["test"] = {
                    result: {
                        total: 0,
                    },
                };
            }
            candidate.id = parseInt(candidate.id);
        });
        setData([...filteredCandidates]);
        setRows([...filteredCandidates]);
    }, [candidates]);

    const pdfDownload = (candidate) => {
        if (
            candidate.productId.customId &&
            (candidate.productId.customId
                .toLowerCase()
                .includes("bcgriseconsulting") ||
                candidate.productId.customId
                    .toLowerCase()
                    .includes("bcgriseyoga") ||
                candidate.productId.customId
                    .toLowerCase()
                    .includes("bcg_problem solving training 2") ||
                userRole.includes("Super"))
        ) {
            setChat(candidate);
            setTranscriptPdfLoaded(true);
        } else {
            fetch(pdfUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    candidate: candidate,
                }),
            }).then(async (res) => {
                if (res.status === 200) {
                    const blob = await res.blob();
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${candidate.name}_${
                        workflowName
                            ? workflowName
                            : driveDetails.name ?? "report"
                    }.pdf`;
                    link.click();
                }
            });
        }
    };

    const requestSearch = (searchValue) => {
        if (searchValue == "") {
            setRows(data);
        } else {
            // Escape the searchValue before creating the regular expression
            const escapedSearchValue = escapeRegExp(searchValue);
            const searchRegex = new RegExp(escapedSearchValue, "i");

            // Filter the data based on the searchRegex
            const filteredRows = data.filter((row) => {
                return (
                    searchRegex.test(row.name) || searchRegex.test(row.email)
                );
            });

            setRows(filteredRows);
        }
    };

    const changeStatus = (status, rowSelected) => {
        if (rowSelected.length == 0) {
            setErrorMsg("Please select atleast one candidate");
            return;
        }

        axios
            .post("/statusupdatebyid", {
                candidates: rowSelected,
                status: status,
            })
            .then(() => {
                setSuccessMsg("Status updated successfully");
                setSelectionModel([]);

                setCheckButtonDisabled((prevState) => {
                    return {
                        ...prevState,
                        [rowSelected.id]: false,
                    };
                });

                if (isReportModelOpen) onCloseModal();
            })
            .catch((err) => {
                redirectUnauthorisedUser(err, navigate);
                setErrorMsg("Could not update status at the moment");
            });
    };
    useEffect(() => {
        if (!transcriptPdfLoaded) {
            const timer = setInterval(function () {
                if (transcriptDownloadRef.current) {
                    clearInterval(timer);
                    transcriptDownloadRef.current.parentNode.click();
                }
            }, 200);
        }
    }, [transcriptPdfLoaded]);

    const formatScore = (row) => {
        let score = 0.0;
        if (filterScoreKey.length) {
            filterScoreKey.forEach((nfsr) => {
                if (
                    row?.test &&
                    row.test?.result &&
                    Object.prototype.hasOwnProperty.call(row.test.result, nfsr)
                ) {
                    score = score + row.test.result[nfsr];
                }
            });
        } else {
            if (
                row?.test &&
                row.test?.result &&
                Object.prototype.hasOwnProperty.call(row.test.result, "total")
            ) {
                score = row.test.result.total;
            }
        }
        if (score) score *= 100;
        return score.toFixed();
    };

    const checkProctoring = (params) => {
        let proctoring = false;
        const { proctoringEvents } = params.test || {};

        if (proctoringEvents && proctoringEvents.length > 0) {
            let count = 0;
            proctoringEvents.forEach((pr) => {
                const {
                    eventType,
                    eventData,
                    resolved,
                    mpResolved,
                    mfResolved,
                    dfResolved,
                } = pr || {};

                if (
                    eventType === "tabChange" ||
                    eventType === "copypaste" ||
                    eventType === "repeatedTest" ||
                    eventType === "ipChange" ||
                    eventType === "windowResize"
                ) {
                    if (!resolved) {
                        count++;
                    }
                } else if (
                    eventType === "mobilePhone" &&
                    eventData &&
                    eventData.mobile_redflag_timestamp
                ) {
                    if (
                        !mpResolved &&
                        eventData.mobile_redflag_timestamp.length > 0
                    ) {
                        count++;
                    }
                } else if (
                    eventType === "multipleFace" &&
                    eventData &&
                    eventData.two_or_more_face_redflag
                ) {
                    if (
                        !mfResolved &&
                        eventData.two_or_more_face_redflag.length > 0
                    ) {
                        count++;
                    }
                } else if (
                    eventType === "differentFace" &&
                    eventData &&
                    eventData.faceid_redflag_timestamp
                ) {
                    if (
                        !dfResolved &&
                        eventData.faceid_redflag_timestamp.length > 0
                    ) {
                        count++;
                    }
                }
            });
            if (count > 0) {
                proctoring = true;
            }
        }
        return proctoring;
    };

    const onOpenProctoringModal = (e, params) => {
        e.stopPropagation();
        setProctoringModal(true);

        const { test } = params || {};
        const { proctoringEvents, userId } = test || {};

        let tabChangeCount = 0;
        let copyPaste = 0;
        let phoneDetected = 0;
        let faceDetected = 0;
        let ipChange = 0;
        let repeatedTest = 0;
        let dfaceDetected = 0;
        let windowResizeCount = 0;

        proctoringEvents.forEach((pr) => {
            const {
                eventType,
                eventData,
                resolved,
                mpResolved,
                mfResolved,
                dfResolved,
            } = pr || {};

            if (eventType === "tabChange") {
                if (!resolved) {
                    tabChangeCount++;
                }
            }
            if (eventType === "copypaste") {
                if (!resolved) {
                    copyPaste++;
                }
            }
            if (eventType === "repeatedTest") {
                if (!resolved) {
                    repeatedTest++;
                }
            }
            if (eventType === "ipChange") {
                if (!resolved) {
                    ipChange++;
                }
            }
            if (eventType === "windowResize") {
                if (!resolved) {
                    windowResizeCount++;
                }
            }
            if (
                eventType === "mobilePhone" &&
                eventData &&
                eventData.mobile_redflag_timestamp
            ) {
                if (!mpResolved) {
                    phoneDetected = eventData.mobile_redflag_timestamp.length;
                }
            }
            if (
                eventType === "multipleFace" &&
                eventData &&
                eventData.two_or_more_face_redflag
            ) {
                if (!mfResolved) {
                    faceDetected = eventData.two_or_more_face_redflag.length;
                }
            }
            if (
                eventType === "differentFace" &&
                eventData &&
                eventData.faceid_redflag_timestamp
            ) {
                if (!dfResolved) {
                    dfaceDetected = eventData.faceid_redflag_timestamp.length;
                }
            }
        });

        const procotoringConcerns = [];
        if (tabChangeCount > 0) {
            procotoringConcerns.push("tabchange");
        }
        if (copyPaste > 0) {
            procotoringConcerns.push("copypaste");
        }
        if (phoneDetected > 0) {
            procotoringConcerns.push("phonedetection");
        }
        if (faceDetected > 0) {
            procotoringConcerns.push("facedetection");
        }
        if (dfaceDetected > 0) {
            procotoringConcerns.push("dfacedetection");
        }
        if (ipChange > 0) {
            procotoringConcerns.push("ipChange");
        }
        if (repeatedTest > 0) {
            procotoringConcerns.push("repeatedTest");
        }
        if (windowResizeCount > 0) {
            procotoringConcerns.push("windowResize");
        }
        let proctoringConcern = "Low";
        if (procotoringConcerns.length >= 3) {
            proctoringConcern = "High";
        } else if (procotoringConcerns.length >= 2) {
            proctoringConcern = "Medium";
        }
        setProctoringData({
            tabChangeCount,
            copyPaste,
            proctoringEvents,
            userId,
            candidateData: params,
            phoneDetected,
            proctoringConcern,
            faceDetected,
            dfaceDetected,
            ipChange,
            repeatedTest,
            windowResizeCount,
        });
        handleSelectApplicant(params);
    };

    const onCloseProctoringModal = () => {
        setProctoringData({});
        setProctoringModal(false);
    };

    const onClickScoreFilter = (e, key, callback) => {
        e.stopPropagation();
        const newFilterScoreKey = [...filterScoreKey];
        if (newFilterScoreKey.includes(key)) {
            const fIndex = newFilterScoreKey.indexOf(key);
            newFilterScoreKey.splice(fIndex, 1);
        } else {
            newFilterScoreKey.push(key);
        }
        if (newFilterScoreKey.length > 0) {
            candidates.forEach((candidate) => {
                candidate.score = 0;
                candidate.id = parseInt(candidate.id);
                newFilterScoreKey.forEach((nfsr) => {
                    if (
                        candidate.test &&
                        candidate.test.result &&
                        Object.prototype.hasOwnProperty.call(
                            candidate.test.result,
                            nfsr
                        )
                    ) {
                        candidate.score =
                            candidate.score + candidate.test.result[nfsr];
                    }
                });
            });
        } else {
            candidates.forEach((candidate) => {
                if (
                    candidate.test &&
                    candidate.test.result &&
                    Object.prototype.hasOwnProperty.call(
                        candidate.test.result,
                        "total"
                    )
                ) {
                    candidate.score = candidate.test.result.total;
                } else {
                    candidate.score = 0;
                }
                candidate.id = parseInt(candidate.id);
            });
        }
        setRows([...candidates]);
        setFilterScoreKey([...newFilterScoreKey]);
        callback();
    };

    const navigateApplicant = (id) => {
        navigate(`/applicant/${id}`, {
            state: {
                sidebar: "reviewApplicants",
                workflowId,
                workflowName,
            },
        });
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: windowDimensions.width / 17,
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => navigateApplicant(params.row._id)}
                        className="userListUser"
                        style={{ cursor: "pointer" }}
                    >
                        {params.row.id}
                    </div>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: useWindowDimensions().width / 5,
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => navigateApplicant(params.row._id)}
                        className="userListUser"
                        style={{
                            fontSize: 16,
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            cursor: "pointer",
                        }}
                    >
                        <div className="tooltip" style={{ width: "100%" }}>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {params.row.name}
                            </div>
                            <span className="tooltiptext datagrid">
                                {params.row.name}
                            </span>
                        </div>
                        <div
                            style={{
                                marginLeft: 15,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {checkProctoring(params.row) ? (
                                <div
                                    className="tooltip"
                                    style={{ width: "100%" }}
                                >
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 20,
                                            backgroundColor: "#E92525",
                                            cursor: "pointer",
                                            flexShrink: 0,
                                            marginRight: params.row.resume
                                                ? 0
                                                : 35,
                                        }}
                                        onClick={(e) =>
                                            onOpenProctoringModal(e, params.row)
                                        }
                                    >
                                        <div
                                            style={{
                                                width: 15,
                                                height: 2,
                                                backgroundColor: "#fff",
                                            }}
                                        />
                                    </div>
                                    <span
                                        className="tooltiptext datagrid"
                                        style={{ top: "110%" }}
                                    >
                                        Red flag prediction for proctoring
                                    </span>
                                </div>
                            ) : null}
                            {params.row.resume ? (
                                <a
                                    href={`${process.env.REACT_APP_STORAGE_BUCKET_URL}${params.row.resume}`}
                                    download
                                    style={{
                                        marginLeft: 15,
                                        display: "inline-flex",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <img
                                        src={CandidateCVIcon}
                                        style={{ height: 20 }}
                                        alt="resume-icon"
                                    />
                                </a>
                            ) : null}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "email",
            headerName: "Email",
            width:
                companyId === "6381099636eb54641ed5c988" ||
                companyId === "64075efbe58189914932e7b1"
                    ? windowDimensions.width / 4.3
                    : windowDimensions.width / 6,
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => navigateApplicant(params.row._id)}
                        className="userListUser"
                        style={{
                            fontSize: 16,
                            fontWeight: "400",
                            width: "100%",
                            cursor: "pointer",
                        }}
                    >
                        <div className="tooltip" style={{ width: "100%" }}>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {params.row.email}
                            </div>
                            <span className="tooltiptext datagrid">
                                {params.row.email}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "score",
            headerName: "Score",
            width: windowDimensions.width / 12,
            headerAlign: "left",
            highlightHeader:
                currentWalkthroughStep === 3 &&
                currentWalkthroughType === "evaluate",
            filterable: filterScoreKeysArr.some((rtr) => rtr !== "total"),
            hideColumn:
                (candidates.length > 0 &&
                    (candidates[0]?.productId?.customId === "Video" ||
                        candidates[0]?.productId?.customId === "BPAD1" ||
                        candidates[0]?.productId?.customId === "BPAD2")) ||
                companyId === "6381099636eb54641ed5c988" ||
                companyId === "64075efbe58189914932e7b1",
            renderFilter: (callback) => {
                return (
                    <div className="row-column-filter">
                        {filterScoreKeysArr.map((rtr) => {
                            return rtr !== "total" ? (
                                <div
                                    key={rtr}
                                    onClick={(e) =>
                                        onClickScoreFilter(e, rtr, callback)
                                    }
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className="filter-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={filterScoreKey.includes(
                                                    rtr
                                                )}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div style={{ whiteSpace: "break-spaces" }}>
                                        {rtr !== "total"
                                            ? workFunctions[rtr.toLowerCase()]
                                                ? workFunctions[
                                                      rtr.toLowerCase()
                                                  ].label
                                                : rtr
                                            : "total"}
                                    </div>
                                </div>
                            ) : null;
                        })}
                    </div>
                );
            },
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => navigateApplicant(params.row._id)}
                        className="userListUser"
                        style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {formatScore(params.row)}%
                    </div>
                );
            },
        },
        {
            field: "report",
            headerName: "Report",
            headerAlign: "center",
            highlightColumn:
                currentWalkthroughStep === 4 &&
                currentWalkthroughType === "evaluate",
            width: windowDimensions.width / 12,
            renderCell: (params) => {
                return (
                    <div
                        className="userListUser"
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {(params.row.test &&
                            params.row.test.result &&
                            Object.prototype.hasOwnProperty.call(
                                params.row.test.result,
                                "total"
                            ) &&
                            !(
                                params.row?.productId?.customId &&
                                (params.row.productId.customId
                                    .toLowerCase()
                                    .includes("bcgriseconsulting") ||
                                    params.row.productId.customId
                                        .toLowerCase()
                                        .includes(
                                            "bcg_problem solving training 2"
                                        ) ||
                                    params.row.productId.customId
                                        .toLowerCase()
                                        .includes("bcgriseyoga"))
                            )) ||
                        params.row.productId.customId === "Video" ? (
                            <div className="tooltip">
                                <Button
                                    className="evaluateStep4"
                                    style={{
                                        background: "#479BD2",
                                        fontSize: 14,
                                        color: "#fff",
                                        fontWeight: "bold",
                                        letterSpacing: "0.01em",
                                        borderRadius: "25px",
                                        padding: "5px 12px",
                                        margin: 0,
                                    }}
                                    size="small"
                                    variant="contained"
                                    onClick={(e) => onOpenModal(e, params)}
                                >
                                    Analyse
                                </Button>
                                <span
                                    className="tooltiptext datagrid"
                                    style={{ width: 250, lineHeight: "1.5" }}
                                >
                                    View reports, video answers, chat
                                    transcripts etc.
                                </span>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <svg
                                    width={24}
                                    height={24}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        pdfDownload(params.row);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
                                </svg>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    if (settings) {
        if (settings.vidanswer)
            columns.push({
                field: "video",
                headerName: "Video",
                width: 150,
                headerAlign: "center",
                renderCell: (params) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                to="/video-submission"
                                state={{
                                    workflowId,
                                    workflowName: workflowName
                                        ? workflowName
                                        : driveDetails.name ?? "report",
                                    sidebar: "reviewApplicants",
                                    candidateData: params.row,
                                }}
                                style={{
                                    fontSize: "14px",
                                    color: "white",
                                    textDecoration: "none",
                                    backgroundColor: "#479BD2",
                                    fontWeight: "bold",
                                    letterSpacing: "0.01em",
                                    borderRadius: "25px",
                                    padding: "5px 12px",
                                    display: "inline-block",
                                }}
                            >
                                Analyse
                            </Link>
                        </div>
                    );
                },
            });
    }

    if (userRole.includes("Admin")) {
        columns.push({
            headerName: "Action",
            headerAlign: "center",
            width: windowDimensions.width / 9,
            highlightColumn:
                currentWalkthroughStep === 9 &&
                currentWalkthroughType === "evaluate",
            renderCell: (params) => {
                return (
                    <>
                        <div
                            className="tooltip"
                            style={{
                                pointerEvents: checkButtonDisabled[
                                    params.row.id
                                ]
                                    ? "none"
                                    : "unset",
                            }}
                        >
                            <IconButton
                                disabled={checkButtonDisabled[params.row.id]}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCheckButtonDisabled({
                                        ...checkButtonDisabled,
                                        [params.row.id]: true,
                                    });
                                    changeStatus("Shortlisted", [params.row]);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="check-btn"
                                    id="reviewApplicantsFlow-selector-7"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span
                                className="tooltiptext"
                                style={{
                                    top: "90%",
                                    lineHeight: "normal",
                                    marginLeft: "-35px",
                                }}
                            >
                                Shortlist
                            </span>
                        </div>
                        <div
                            className="tooltip"
                            style={{
                                pointerEvents: checkButtonDisabled[
                                    params.row.id
                                ]
                                    ? "none"
                                    : "unset",
                            }}
                        >
                            <IconButton
                                disabled={checkButtonDisabled[params.row.id]}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCheckButtonDisabled({
                                        ...checkButtonDisabled,
                                        [params.row.id]: true,
                                    });
                                    changeStatus("Rejected", [params.row]);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="cancel-btn"
                                    id="reviewApplicantsFlow-selector-8"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span
                                className="tooltiptext"
                                style={{
                                    top: "90%",
                                    lineHeight: "normal",
                                    marginLeft: "-35px",
                                }}
                            >
                                Reject
                            </span>
                        </div>
                        <div
                            className="tooltip"
                            style={{
                                pointerEvents: checkButtonDisabled[
                                    params.row.id
                                ]
                                    ? "none"
                                    : "unset",
                            }}
                        >
                            <IconButton
                                disabled={checkButtonDisabled[params.row.id]}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCheckButtonDisabled({
                                        ...checkButtonDisabled,
                                        [params.row.id]: true,
                                    });
                                    changeStatus("Kept In View", [params.row]);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="visibility-btn"
                                    id="reviewApplicantsFlow-selector-9"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span
                                className="tooltiptext"
                                style={{
                                    top: "90%",
                                    lineHeight: "normal",
                                    marginLeft: "-35px",
                                }}
                            >
                                Keep in view
                            </span>
                        </div>
                    </>
                );
            },
        });
    }

    useEffect(() => {
        if (selectionModel.length == 0) setSelectedRow([]);
        if (selectionModel.length == 1)
            setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
        else {
            let temp = [];

            for (let i = 0; i < selectionModel.length; i++) {
                temp.push(...rows.filter((row) => selectionModel[i] == row.id));
            }

            setSelectedRow(temp);
        }
    }, [selectionModel]);

    const onOpenEditModal = () => {
        const isValidCandidateSelection = mediaQueryMobile.matches
            ? selectedRow.length === 1
            : selectionModel.length === 1;

        if (isValidCandidateSelection) {
            setEditModal(true);
        } else {
            setErrorMsg("Please select a candidate");
        }
    };

    const onCloseEditModal = () => setEditModal(false);

    const MenuItems = [
        {
            name: "Edit",
            action: () => {
                onOpenEditModal();
            },
        },
        {
            name: "Delete",
            action: () => {
                onOpenDeleteModal();
            },
        },
        {
            name: "Mark as",
            action: () => {
                setBottomDrawer(true);
            },
        },
        {
            name: "Analyse",
            action: () => onOpenModal(null, { row: selectedRow[0] }),
        },
        {
            name: "Export",
            CSVData: CSVData,
        },
    ];

    const handleSelectApplicant = (row) => {
        if (
            selectedRow.length !== 0 &&
            selectedRow.some((el) => el._id === row._id)
        ) {
            setSelectedRow(selectedRow.filter((el) => el._id !== row._id));
        } else {
            setSelectedRow([...selectedRow, row]);
        }
    };

    const resolveProctoringStatus = (events, candidateRow) => {
        if (candidateRow.length == 0) {
            setErrorMsg("Please select atleast one candidate");
            return;
        }

        for (let eventType of events) {
            axios
                .post("/test/resolveproctoring", {
                    eventType,
                    candidate: candidateRow[0],
                })
                .then(() => {
                    setSuccessMsg("Proctoring resolved successfully");
                    setSelectionModel([]);
                    onCloseProctoringModal();
                })
                .catch((err) => {
                    redirectUnauthorisedUser(err, navigate);
                    setErrorMsg("Could not update status at the moment");
                });
        }
    };

    return (
        <div className="productList">
            {mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
            <div className="inner-div" style={{ marginBottom: "20px" }}>
                <div className="top-icon-box">
                    <WalkthroughWelcome />
                    {userRole.includes("Admin") ? (
                        <>
                            <div className="tooltip">
                                <IconButton
                                    onClick={onOpenEditModal}
                                    id="reviewApplicantsFlow-selector-3"
                                >
                                    <img
                                        src={Edit}
                                        alt="edit-icon"
                                        width={30}
                                        height={30}
                                    />
                                </IconButton>
                                <span className="tooltiptext">Edit</span>
                            </div>
                            <div className="tooltip">
                                <IconButton onClick={() => onOpenDeleteModal()}>
                                    <img
                                        src={Trash}
                                        alt="delete-icon"
                                        width={30}
                                        height={30}
                                    />
                                </IconButton>
                                <span className="tooltiptext">Delete</span>
                            </div>
                        </>
                    ) : null}
                    <NotificationBell notification={notification} />
                    <AccountMenu />
                </div>
            </div>
            {chat.test &&
            chat.test.result &&
            Object.prototype.hasOwnProperty.call(chat.test.result, "total") &&
            !(
                chat.productId &&
                chat.productId.customId &&
                (chat.productId.customId
                    .toLowerCase()
                    .includes("bcgriseconsulting") ||
                    chat.productId.customId
                        .toLowerCase()
                        .includes("bcgriseyoga") ||
                    chat.productId.customId
                        .toLowerCase()
                        .includes("bcg_problem solving training 2"))
            ) ? (
                <>
                    {chat.productId &&
                    chat.productId.customId === "Video" ? null : (
                        <Doughnut
                            ref={doughnutRef}
                            data={doughnutData(totalScore)}
                            options={doughnutOptions}
                            style={{ width: 100, height: 100, display: "none" }}
                        />
                    )}
                    {barData.labels.length > 0 ? (
                        <Bar
                            ref={barChartRef}
                            data={barData}
                            options={barData.options}
                            style={{ display: "none" }}
                        />
                    ) : null}
                </>
            ) : null}
            {chat.productId &&
            chat.productId.customId &&
            (chat.productId.customId
                .toLowerCase()
                .includes("bcgriseconsulting") ||
                chat.productId.customId.toLowerCase().includes("bcgriseyoga") ||
                chat.productId.customId
                    .toLowerCase()
                    .includes("bcg_problem solving training 2") ||
                userRole.includes("Super")) ? (
                <div style={{ display: "none" }}>
                    <PDFDownloadLink
                        document={<PdfTranscript candidateData={chat} />}
                        fileName={
                            chat.name
                                ? `${chat.name.split(" ").join("_")}_${
                                      workflowName
                                          ? workflowName.split(" ").join("_")
                                          : driveDetails.name
                                                .split(" ")
                                                .join("_") ?? "report"
                                  }.pdf`
                                : "report.pdf"
                        }
                    >
                        {({ loading }) => {
                            if (loading) {
                                return <div>Loading...</div>;
                            } else {
                                setTranscriptPdfLoaded(false);
                                return (
                                    <div className="tooltip">
                                        <div ref={transcriptDownloadRef}>
                                            Download
                                        </div>
                                        <span className="tooltiptext">
                                            Download candidate report
                                        </span>
                                    </div>
                                );
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            ) : null}
            <div
                className="userListTitleContainer"
                style={{ padding: "0 20px" }}
            >
                <div className="inner-div-details">
                    <h2>Review Applicants</h2>
                    <p style={{ wordBreak: "break-all" }}>
                        {workflowName
                            ? workflowName
                            : driveDetails.name ?? "report"}
                    </p>
                </div>
                <div className="search-box review-applicants">
                    <input
                        type="search"
                        placeholder="Search"
                        onChange={(event) => requestSearch(event.target.value)}
                    />
                </div>
                {userRole.includes("Admin") ? (
                    <div className="notification-btns-box review-applicants">
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={() =>
                                        changeStatus("Shortlisted", selectedRow)
                                    }
                                    className="check-btn"
                                    id="reviewApplicantsFlow-selector-10"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Shortlist</span>
                        </div>
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={() =>
                                        changeStatus("Rejected", selectedRow)
                                    }
                                    className="cancel-btn"
                                    id="reviewApplicantsFlow-selector-11"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Reject</span>
                        </div>
                        <div className="tooltip">
                            <IconButton>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    onClick={() =>
                                        changeStatus(
                                            "Kept In View",
                                            selectedRow
                                        )
                                    }
                                    className="visibility-btn"
                                    id="reviewApplicantsFlow-selector-12"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Keep in view</span>
                        </div>
                        {userRole.includes("Super") ? (
                            <ConcludeAssessment workflowId={workflowId} />
                        ) : null}
                    </div>
                ) : null}
                <div className="export-btn review-applicants">
                    <div className="tooltip">
                        <Button
                            disabled={!CSVData.length}
                            style={{
                                background: "#479BD2",
                                fontWeight: "bold",
                                letterSpacing: "0.01em",
                                borderRadius: "25px",
                                padding: "8px 20px",
                            }}
                            size="small"
                            variant="contained"
                        >
                            <CSVLink
                                filename="Data.csv"
                                data={CSVData}
                                target="_blank"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                }}
                                id="reviewApplicantsFlow-selector-2"
                            >
                                Export
                                <img
                                    src={Subtract}
                                    alt=""
                                    style={{
                                        height: "18px",
                                        width: "18px",
                                        transform: `translate(3px, 4px)`,
                                    }}
                                />
                            </CSVLink>
                        </Button>
                        <span className="tooltiptext">Export report</span>
                    </div>
                </div>
            </div>
            {selectionModel.length < 0 ? (
                <div
                    style={{
                        background: "lightgrey",
                        minHeight: "6vh",
                        padding: "1vh 20px 1vh",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={() => {
                                changeStatus("Withdraw", selectedRow);
                            }}
                            style={{
                                color: "#fff",
                                background: "#0C95BA ",
                                fontSize: "12px",
                                height: "40px",
                                borderRadius: "25px",
                                padding: "5px 20px",
                                marginRight: "10px",
                            }}
                        >
                            Withdraw
                        </Button>
                    </div>
                </div>
            ) : null}
            {mediaQueryMobile.matches && (
                <div className="review-applicant-box__content">
                    {rows.length === 0 ? (
                        <>
                            <img
                                src={emptyRowImg}
                                alt="no rows"
                                style={{
                                    height: "auto",
                                    width: "100%",
                                }}
                            />
                            <p
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#334D6E",
                                    textAlign: "center",
                                }}
                            >
                                No data to show
                            </p>
                        </>
                    ) : null}
                    {rows.map((row, index) => (
                        <div
                            key={index}
                            className="applicant-box__row"
                            onClick={() => handleSelectApplicant(row)}
                        >
                            <div className="applicant-box__row-left">
                                <div className="applicant-box__row-left-image-box">
                                    <Avatar
                                        name={parseInt(row.id)}
                                        styles={{
                                            text: {
                                                backgroundColor: "#EBEBEB",
                                                color: "#404040",
                                            },
                                        }}
                                    />
                                    {selectedRow.length !== 0 &&
                                        selectedRow.some(
                                            (el) => el._id === row._id
                                        ) && (
                                            <img
                                                src={TickBlueIcon}
                                                alt="right-blue-icon"
                                            />
                                        )}
                                </div>
                                <div className="applicant-box-row-left-text-box">
                                    <p className="applicant-box__row-left-text-name">
                                        {row.name}
                                    </p>
                                    <p className="applicant-box__row-left-text-role">
                                        {row.email}
                                    </p>
                                </div>
                            </div>
                            <div className="applicant-box__row-right">
                                <p className="applicant-box__row-right-score">
                                    {formatScore(row)}%
                                </p>
                                <p className="applicant-box__row-left-text-role">
                                    Score
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!mediaQueryMobile.matches && (
                <div
                    style={{
                        minHeight: 500,
                        height: "max-content",
                        padding: 10,
                        background: "#fff",
                        borderRadius: 25,
                        overflow: "hidden",
                        display: "flex",
                        flex: 1,
                    }}
                >
                    <DataTable
                        loading={applicantsLoading}
                        noRowsComponent={<NoRowsOverlay />}
                        height={"20px"}
                        rows={
                            isOpen ||
                            (currentWalkthroughStep > 0 &&
                                currentWalkthroughType === "evaluate" &&
                                rows.length === 0)
                                ? [
                                      {
                                          _id: "61876d63673eccda481f16ef",
                                          name: "Nancy Brown",
                                          email: ["nancy534@gmail.com"],
                                          id: "30",
                                          code: "2a10RHuOLbRUdj9qZsEStxo4Xe3Tbem2GnbbGUTwrHkGy3FKJu3KbJ5G",
                                          status: "Shortlisted",
                                          lastActive:
                                              "2021-11-07T06:11:30.544Z",
                                          createdDate:
                                              "2021-11-07T06:08:35.734Z",
                                          company: "61730080f94921c9089ea99f",
                                          workflow: "6178704387c9e1c238ae4f58",
                                          __v: 0,
                                          problem:
                                              "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
                                          productId: "ChatId4",
                                          title: "HR Evaluative",
                                          test: {
                                              result: {
                                                  total: 0,
                                              },
                                          },
                                      },
                                  ]
                                : rows
                        }
                        columns={columns}
                        checkboxSelection={userRole.includes("Admin")}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        pageSize={15}
                    />
                </div>
            )}
            {mediaQueryMobile.matches && (
                <Drawer
                    anchor="bottom"
                    open={bottomDrawer}
                    onClose={() => {
                        setBottomDrawer(false);
                        setCheckboxOptions({
                            shortlist: false,
                            reject: false,
                            keptInView: false,
                        });
                    }}
                    PaperProps={{
                        style: {
                            padding: "1rem 20px",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                        },
                    }}
                >
                    <div className="bottomDrawer">
                        <div className="bottomDrawer__img-box">
                            <img
                                src={Cancel}
                                alt="cancel-icon"
                                width={20}
                                height={20}
                                onClick={() => setBottomDrawer(false)}
                            />
                        </div>
                        <div className="bottomDrawer__checkbox-grp">
                            <input
                                type="checkbox"
                                id="shortlisted"
                                checked={checkboxOptions.shortlist}
                                onChange={() => {
                                    setCheckboxOptions({
                                        shortlist: !checkboxOptions.shortlist,
                                        reject: false,
                                        interview: false,
                                    });
                                }}
                            />
                            <label htmlFor="shortlisted">Shortlist</label>
                        </div>
                        <div className="bottomDrawer__checkbox-grp">
                            <input
                                type="checkbox"
                                id="rejected"
                                checked={checkboxOptions.reject}
                                onChange={() => {
                                    setCheckboxOptions({
                                        reject: !checkboxOptions.reject,
                                        shortlist: false,
                                        interview: false,
                                    });
                                }}
                            />
                            <label htmlFor="rejected">Reject</label>
                        </div>
                        <div className="bottomDrawer__checkbox-grp">
                            <input
                                type="checkbox"
                                id="keptinview"
                                checked={checkboxOptions.keptInView}
                                onChange={() => {
                                    setCheckboxOptions({
                                        keptInView: !checkboxOptions.keptInView,
                                        shortlist: false,
                                        reject: false,
                                    });
                                }}
                            />
                            <label htmlFor="keepinview">Keep in view</label>
                        </div>

                        <Button
                            className="btn-primary"
                            onClick={() => {
                                if (checkboxOptions.shortlist) {
                                    changeStatus("Shortlisted", selectedRow);
                                } else if (checkboxOptions.reject) {
                                    changeStatus("Rejected", selectedRow);
                                } else {
                                    changeStatus("Kept in view", selectedRow);
                                }
                                setBottomDrawer(false);
                            }}
                            disabled={
                                !checkboxOptions.shortlist &&
                                !checkboxOptions.reject &&
                                !checkboxOptions.keptInView
                            }
                            id="quickViewFlow-selector-8"
                        >
                            Mark
                        </Button>
                    </div>
                </Drawer>
            )}
            <AnalyseModal
                open={isReportModelOpen}
                onClose={onCloseModal}
                changeStatus={changeStatus}
                doughnutRef={doughnutRef}
                barChartRef={barChartRef}
                isOpen={isOpen}
                change={change}
                setChange={setChange}
                driveDetails={driveDetails}
                workFunctions={workFunctions}
                nextWalkthroughStep={nextWalkthroughStep}
                setNextWalkthroughStep={setNextWalkthroughStep}
                updateVideoSubmissionScore={updateVideoSubmissionScore}
            />
            <Modal open={deleteModal} onClose={onCloseDeleteModal} center>
                <p className="modal-text">
                    Do you want to delete this candidate?
                </p>
                <div className="button-div">
                    <Button
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "6px 20px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            handleDelete(selectedRow);
                        }}
                        isLoading={isDeletingCand}
                    >
                        Yes
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "6px 20px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            onCloseDeleteModal();
                        }}
                    >
                        No
                    </Button>
                </div>
            </Modal>
            <Modal
                open={proctoringModal}
                onClose={onCloseProctoringModal}
                center
            >
                <ProctoringModal
                    proctoringData={proctoringData}
                    onCloseProctoringModal={onCloseProctoringModal}
                    selectedRow={selectedRow}
                    resolveProctoringStatus={resolveProctoringStatus}
                    changeStatus={changeStatus}
                />
            </Modal>
            <Modal open={editModal} onClose={onCloseEditModal} center>
                <p className="modal-text">Edit candidate&apos;s name</p>
                {selectedRow.length == 1 ? (
                    <>
                        <div className="input-div-workflow">
                            <label
                                style={{
                                    margin: "0 15px",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                }}
                            >
                                New name
                            </label>
                            <br />
                            <input
                                id="reviewApplicantsFlow-selector-4"
                                type="text"
                                ref={editNameRef}
                                defaultValue={selectedRow[0].name}
                                placeholder="Name"
                                style={{
                                    background: "#F2F2F2",
                                    borderRadius: "30px",
                                }}
                                required
                            />
                        </div>
                    </>
                ) : (
                    "Loading"
                )}
                <div className="button-div">
                    <Button
                        id="reviewApplicantsFlow-selector-5"
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            nameEdit(selectedRow[0]);
                        }}
                        isLoading={isEditingName}
                    >
                        Ok
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            onCloseEditModal();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            {mediaQueryMobile.matches && <BottomBar />}
            {(currentWalkthroughStep > 0 && currentWalkthroughStep <= 4) ||
            (currentWalkthroughStep === 9 &&
                currentWalkthroughType === "evaluate") ? (
                <WalkthroughOverlay
                    showWalkthroughNextBtn={!(currentWalkthroughStep === 4)}
                    onClickDone={() => {
                        if (nextWalkthroughStep <= 9) {
                            if (nextWalkthroughStep === 5) {
                                setCandidateTestReport(rows[0]);
                                setReportModel(true);
                            }
                            updateCurrentWalkthroughStep(nextWalkthroughStep);
                            setNextWalkthroughStep(nextWalkthroughStep + 1);
                        } else {
                            onCloseModal();
                            updateCurrentWalkthroughStep(0);
                            updateCurrentWalkthroughType("");
                            let wArr = [];
                            if (localStorage.getItem("walkthroughFlows")) {
                                wArr = JSON.parse(
                                    localStorage.getItem("walkthroughFlows")
                                );
                            }
                            wArr.push("evaluate");
                            localStorage.setItem(
                                "walkthroughFlows",
                                JSON.stringify(wArr)
                            );
                            updateWalkthroughFlows(wArr);
                        }
                    }}
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        if (nextWalkthroughStep >= 4)
                            setNextWalkthroughStep(nextWalkthroughStep - 1);
                        if (currentWalkthroughStep === 3) {
                            navigate(`/workflows/${workflowName}`, {
                                state: {
                                    sidebar: "quickLook",
                                    workflowId,
                                    workflowName,
                                },
                            });
                        }
                    }}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    candidates: getCandidatesList(),
    rerender: getRerender(),
    isReportModelOpen: getIsReportModelOpen(),
    userRole: getUserRole(),
    applicantsLoading: getApplicantsLoading(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
    driveDetails: getDriveDetails(),
    skillCodeList: getSkillCodeList(),
    isEditingName: checkIfLoading(EDIT_CANDIDATE_NAME),
    isDeletingCand: checkIfLoading(DELETE_CANDIDATE),
});

const mapDispatchToProps = (dispatch) => ({
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
    editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
    resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
    resetRerender: () => dispatch(resetRerenderRequest()),
    deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
    setReportModel: (data) => dispatch(setReportModelRequest(data)),
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) =>
        dispatch(updateCurrentWalkthroughTypeRequest(data)),
    updateWalkthroughFlows: (data) =>
        dispatch(updateWalkthroughFlowsRequest(data)),
    fetchDriveDetails: (data) => dispatch(fetchDriveDetailsRequest(data)),
    fetchSkillCodes: (data) => dispatch(fetchSkillCodesRequest(data)),
    setCandidates: (data) => dispatch(setCandidatesRequest(data)),
    setCandidateTestReport: (data) =>
        dispatch(setCandidateTestReportRequest(data)),
    setSuccessMsg: (data) => dispatch(setSuccessMsgRequest(data)),
    setErrorMsg: (data) => dispatch(setErrorMsgRequest(data)),
});

ReviewApplicants.propTypes = {
    candidates: PropTypes.array,
    getCandidatesByType: PropTypes.func,
    rerender: PropTypes.number,
    editCandidateName: PropTypes.func,
    resetRerender: PropTypes.func,
    deleteCandidate: PropTypes.func,
    isReportModelOpen: PropTypes.bool,
    setReportModel: PropTypes.func,
    notification: PropTypes.object,
    userRole: PropTypes.string,
    applicantsLoading: PropTypes.bool,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
    updateCurrentWalkthroughType: PropTypes.func,
    updateWalkthroughFlows: PropTypes.func,
    fetchDriveDetails: PropTypes.func,
    driveDetails: PropTypes.object,
    fetchSkillCodes: PropTypes.func,
    skillCodeList: PropTypes.object,
    setCandidates: PropTypes.func,
    setCandidateTestReport: PropTypes.func,
    setSuccessMsg: PropTypes.func,
    setErrorMsg: PropTypes.func,
    isEditingName: PropTypes.bool,
    isDeletingCand: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewApplicants);
