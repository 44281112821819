import React from "react";
import PropTypes from "prop-types";

import "../../styles/IconButton.scss";
import Loader from "./Loader";

const IconButton = (props) => {
    const { className, children, disabled, onClick, isLoading, ...rest } =
        props;

    if (isLoading) {
        return (
            <button
                className={`icon-button ripple-effect ${
                    className ? className : ""
                }`}
                disabled={true}
                {...rest}
            >
                <Loader
                    size={22}
                    style={{
                        padding: "0",
                        margin: "0",
                    }}
                />
            </button>
        );
    }

    return (
        <button
            className={`icon-button ripple-effect ${
                className ? className : ""
            }`}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {children}
        </button>
    );
};

IconButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default IconButton;
